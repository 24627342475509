
import { functions } from './modules/functions'

window.addEventListener('DOMContentLoaded', () => {
    const app = document.getElementById('app');
    if(app) {
        const dataSrc = app.dataset.src;
        if(dataSrc) {
            functions.initForm(dataSrc);
        }
    }
});
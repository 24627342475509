
const internalFunctions = {
    getData: function (data, search) {
        return data.find(item => item.value === search);
    },
    clearSelect: function (select, firstOption) {
        select.setAttribute('disabled', 'disabled');
        select.innerHTML = '';
        select.add(firstOption);
    },
    enableElement: function (element, enabled = true) {
        if(enabled) {
            element.removeAttribute('disabled');
        }
        else {
            element.setAttribute('disabled', 'disabled');
        }
    }
}

const functions = {
    initForm: function (datasrc = 'camping') {
        let selectCampingsite = document.getElementById('selectCampingplatz');
        let selectCampingType = document.getElementById('selectCampingTyp');
        let selectCampingLocation = document.getElementById('selectCampingLocation');
        let buttonSubmit = document.getElementById('buttonSubmit');


        if(selectCampingsite && selectCampingType && selectCampingLocation && buttonSubmit) {

            const selectCampingTypeText = selectCampingType.options[0].text;
            const selectCampingLocationText = selectCampingLocation.options[0].text;

            fetch(`data/${datasrc}.json`)
                .then(response => {
                    if(!response.ok) {
                        throw new Error('Could not load campingsites');
                    }
                    return response.json();
                })
                .then(data => {
                    if(data.campingsites.length) {

                        data.campingsites.forEach(campingsite => {
                            const option = new Option(campingsite.name, campingsite.value);
                            selectCampingsite.add(option);
                        });

                        selectCampingsite.addEventListener('change', (e) => {
                            internalFunctions.clearSelect(selectCampingType, new Option(selectCampingTypeText, ''));
                            internalFunctions.clearSelect(selectCampingLocation, new Option(selectCampingLocationText, ''));
                            internalFunctions.enableElement(buttonSubmit, false);
                            if (selectCampingsite.value !== '' && selectCampingsite.value !== undefined) {
                                const campingsite = internalFunctions.getData(data.campingsites, selectCampingsite.value);
                                if (campingsite !== undefined) {
                                    if (campingsite.types.length) {
                                        internalFunctions.clearSelect(selectCampingType, new Option(selectCampingTypeText, ''));
                                        selectCampingType.removeAttribute('disabled');
                                        campingsite.types.forEach(item => {
                                            const option = new Option(item.name, item.value);
                                            selectCampingType.add(option);
                                        });
                                    }
                                }
                            }
                        });


                        selectCampingType.addEventListener('change', (e) => {
                            internalFunctions.clearSelect(selectCampingLocation, new Option(selectCampingLocationText, ''));
                            internalFunctions.enableElement(buttonSubmit, false);
                            if (selectCampingsite.value !== '' && selectCampingsite.value !== undefined) {
                                const campingsite = internalFunctions.getData(data.campingsites, selectCampingsite.value);
                                if (campingsite !== undefined) {
                                    if (selectCampingType.value !== '' && selectCampingType.value !== undefined) {
                                        const type = internalFunctions.getData(campingsite.types, selectCampingType.value);
                                        if (type !== undefined && type.items.length) {
                                            selectCampingLocation.removeAttribute('disabled');
                                            type.items.forEach(item => {
                                                const option = new Option(item.name, item.value);
                                                selectCampingLocation.add(option);
                                            });
                                        }
                                    }
                                }
                            }
                        });

                        selectCampingLocation.addEventListener('change', (e) => {
                           if(selectCampingLocation.value !== '' && selectCampingsite.value !== undefined) {
                               internalFunctions.enableElement(buttonSubmit);
                           }
                           else {
                               internalFunctions.enableElement(buttonSubmit, false);
                           }
                        });

                        buttonSubmit.addEventListener('click', (e) => {
                            e.preventDefault();
                            const parameter = selectCampingLocation.value;
                            if(parameter !== undefined && parameter !== '') {
                                window.location.href = `https://portal.lecker-morgen.de/login_feriengast.php?login=${parameter}`;
                            }
                        });

                    }

                })
                .catch(error => {
                    console.error('Error collecting json data', error);
                })
        }
    }
}

export { functions }